<template>
    <v-card class="mx-auto">
      <v-progress-linear v-show="showProgress" :indeterminate="true" class="ma-0 pa-0"
                         style="position: absolute; top: 0; left: 0"/>
      <v-toolbar :color="($vuetify.theme.dark) ? 'dark' : 'white'">
        <v-toolbar-title>{{ ibq.h }}</v-toolbar-title>
      </v-toolbar>
      <v-container fluid>


        <!--          <v-card-title primary-title width="100%">-->
        <!--                <div style="width:100%">-->
        <!--                    <div class="headline indigo&#45;&#45;text">{{ibq.h}}</div>-->
        <!--                    <hr class="red darken-1"/>-->
        <!--                </div>-->
        <!--            </v-card-title>-->
        <!--suppress HtmlUnknownTarget -->
        <v-row align="center" class="pa-2 ma-16" justify="center">

          <!--suppress HtmlUnknownTarget -->
          <v-img
              :src="processIBQUrl(ibq.url)"

              class="hidden-md-and-up white--text ma-0 pa-0"
              contain
              lazy-src="https://dentalpockets.com/images/placeholder.png"
              max-height="40vh"
              max-width="65vw"
                        min-height="40vh"

                        v-if="Object.keys(ibq).length > 0"
                >

                    <template v-slot:placeholder>
                        <v-row
                                align="center"
                                class="fill-height ma-0"
                                justify="center"
                        >
                            <v-progress-circular color="pink" indeterminate/>
                        </v-row>
                    </template>
                </v-img>
                <v-progress-circular class="hidden-md-and-up" color="pink" indeterminate v-else/>


                <!--suppress HtmlUnknownTarget -->
                <v-img
                        :src="processIBQUrl(ibq.url)"
                        class="hidden-sm-and-down white--text align-end"
                        contain
                        lazy-src="https://dentalpockets.com/images/placeholder.png"
                        max-height="400"
                        max-width="400"
                        min-height="350"
                        v-if="Object.keys(ibq).length > 0"
                >


                    <template v-slot:placeholder>
                        <v-row
                                align="center"
                                class="fill-height ma-0"
                                justify="center"
                        >
                            <v-progress-circular color="pink" indeterminate/>
                        </v-row>
                    </template>
                </v-img>

                <v-progress-circular class="hidden-sm-and-down" color="pink" indeterminate v-else/>

            </v-row>
            <v-card-title style="word-break: normal" v-html="ibq.question"/>


            <v-card-actions>
                <v-btn @click="submit" color="pink darken-1 white--text" v-if="!show">Show Answer</v-btn>
                <v-spacer/>
                <v-btn @click="show = !show" icon>
                    <v-icon>{{ show ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                </v-btn>
            </v-card-actions>

            <v-slide-y-transition>
                <v-card-text class="subtitle-1" v-html="ibq.answer" v-show="show"/>
            </v-slide-y-transition>
        </v-container>
    </v-card>
</template>
<script>
    export default {
        props: {
            ibq: {
                url: "",
                answer: "",
                question: ""
            },
            showProgress: {}
        },

        data: () => ({
            show: false,
        }),
        methods: {
            submit() {
                this.show = !this.show;
            },
            processIBQUrl(url) {
                return url.replace("http:", "https:");
            }
        },
        watch: {
            ibq: {
                immediate: true,
                deep: true,
                handler(newValue, oldValue) {
                    if (newValue !== oldValue) {
                        this.show = false;
                    }

                }
            }
        },
        beforeDestroy() {
            this.show = false;
        },

    };
</script>
<!--suppress CssUnusedSymbol -->
<style scoped>
    .v-card__text, .v-card__title {
        word-break: normal; /* maybe !important  */
    }
</style>