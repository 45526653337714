<template>
    <v-container @click.self="parent" fluid grid-list-lg v-cloak>
        <v-layout row wrap>
            <v-row
                    class="mb-6"
                    no-gutters
            >
                <v-flex sm9 xs12>
                    <!--                    <v-progress-linear :indeterminate="true" v-show="showProgress"/>-->
                    <IBQComponent :ibq="ibqsdata" :showProgress="showProgress" v-if="renderComponent"/>
                </v-flex>
                <v-flex class="align-self-center" sm3 xs12>
                    <v-row align="center" class="mt-3" justify="center">
                        <v-btn :loading="loading5" @click="NextQuestionPlease" color="indigo"
                               dark x-large>
                            Ask Another
                        </v-btn>
                    </v-row>
                </v-flex>

            </v-row>

        </v-layout>

    </v-container>
</template>

<script>
    import IBQComponent from "../components/IBQComponent";
    import {APIService} from "../APIService";

    const apiService = new APIService();

    export default {
        metaInfo: {
            title: 'Image Based Questions',
            titleTemplate: '%s - Dental Pockets ||| Mastering Dentisty the Easy Way!',
            htmlAttrs: {
                lang: 'en',
                amp: true
            },
            meta: [
                {httpEquiv: 'Content-Type', content: 'text/html; charset=utf-8'},
                {
                    name: 'description',
                    content: "NEET, NBDE, Dental Exams, PGI, JIPMER, DHA, preparation of dental exams now easy. Image based questions are now made easy."
                }
            ]
        },
        name: "Ibqs",
        components: {IBQComponent},
        data: () => ({
            ibqsdata: {},
            fab: false,
            showProgress: true,
            renderComponent: true,
            loading5: false,
        }),
        mounted: function () {
            this.$nextTick(function () {

                // entire view has been rendered
                this.getIBQFromServer();


            });
        },
        methods: {
            getIBQFromServer() {
                const app = this;
                app.showProgress = true;
                app.loading5 = true;

                apiService.getIBQFromServer().then(data => {


                    app.ibqsdata = data;
                    app.ibqsdata.h = "Image Based Questions";
                    app.show = !app.show;
                    app.showProgress = false;
                    app.loading5 = false
                });
            },
            NextQuestionPlease() {
                this.getIBQFromServer();
            },
            onScroll(e) {
                if (typeof window === 'undefined') return;
                const top = window.pageYOffset || e.target.scrollTop || 0;
                this.fab = top > 20
            },
            toTop() {
                this.$vuetify.goTo(0)
            }
        },
        beforeDestroy() {
            this.ibqsdata = {};

        },
    }
</script>

<style scoped>
    .action-container {
        display: flex;
        align-items: center;
    }

    .v-btn:hover:before {
        opacity: 0 !important;
    }
</style>